//Определяет тему приложения. Переменные создаются для вещей используемых глобально или имеющих ключевое значение

//Минимальная ширина контента
$contentMinWidth: 320px;
//Максимальная ширина контента
$contentMaxWidth: 1024px;
//Отступ от краев экрана и большинства элементов друг от друга
$contentDefaultIndent: 10px;
//Высота основного мобильного хедера
$mobileHeaderHeight: 50px;
//Высота основного десктопного хедера
$desktopHeaderHeight: 80px;
//Высота мобильного горизонтального nav
$mobileNavHeight: 40px;
//Ширина боковых меню
$sideMenuWidth: 270px;
//Высота основного футера
$appFooterHeight: 25px;
//Высота хедера окна
$windowHeaderHeight: 30px;
//Основной размер шрифта, остальные, в идеале, задаются относительно, через em
$uiFontSize: 14px;
//Основной тёмный цвет темы
$uiDarkColor: #031418;
//Основной светлый цвет темы
$uiLightColor: #206865;
//Дополнительный, нейтральный цвет, для фонов и ui
$uiNeutralColor: #032429;
//Цвет подсветки - выделение, наведение...
$uiHighlightColor: #c1fffd;
//Цвет оверлея, окон, меню, прелоадеров
$uiOverlayColor: rgba(3, 20, 24, 0.75);
//Цвет шрифта
$uiFontColor: #fff;
//Цвет ссылок
$uiLinkColor: #a2fffd;
//Цвет обводи элементов
$uiBorderColor: #09292e;
//Длительность анимаций ui
$uiAnimationDuration: 0.4s;
//Тень ui элементов
$uiComponentShadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
//Прозрачность задизейбленного элемента
$uiComponentDisabledOpacity: 0.5;
//Градиентная обводка ui элементов
$uiComponentBorder: linear-gradient(10deg, $uiBorderColor 0, $uiLightColor 50%, $uiBorderColor 100%) 1;
//Градиентный разделитель
$uiDividerBorder: linear-gradient(to left, $uiBorderColor 0, $uiLightColor 50%, $uiBorderColor 100%) 1 stretch;
//Цвет фона полей ввода
$inputBackgroundColor: #d1eaea;
//Размер иконок соц. сетей
$uiSocialIconSize: 40px;
//ширина модалки истории игр
$historyModalWidth: 631;
//Цвет обводки на странице чата
$chatPageBorderColor: rgba(55,174,190,1);
//Цвет фона сообщений
$messageBg: rgba(29,89,97,1);
//Цвет обводки сообщений чата
$messageBorder: rgba(17,112,117,1);
//Белый цвет  иконок СВГ
$WhiteSVGColor: rgba(224,224,224,1);
