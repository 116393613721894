//Это основной файл стилей, подключается в index.jsx. Тут только глобальные стили
@import url(https://fonts.googleapis.com/css?family=Roboto:400,700&subset=cyrillic);
@import "normalize-scss";
@import "config";

//Инициализируем normalize-scss
@include normalize();

*,
*::before,
*::after {
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  box-sizing: border-box;
  outline: none;
}

[disabled] {
  opacity: $uiComponentDisabledOpacity;
  pointer-events: none;
  [disabled] {
    opacity: 1;
  }
}

html,
body,
#root {
  height: 100%;
}

#root {
  overflow-y: auto;
}

body.open-chat > #root {
  overflow-y: hidden;
}

body,
input,
button {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Roboto", sans-serif;
  text-size-adjust: none;
}

body {
  margin: 0;
  padding: 0;
  //overflow-y: scroll;
  background-color: $uiDarkColor;
  color: $uiFontColor;
  font-size: $uiFontSize;
  text-align: center;
  user-select: none;
}

body.open-chat {
  touch-action: none;
  -ms-touch-action: none;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
  td {
    padding: 0;
  }
}

a {
  color: $uiLinkColor;
  font-weight: bold;
  &:active {
    opacity: 0.6;
  }
}
form {
  label,
  input[type=radio],
  input[type=checkbox] {
    margin: $contentDefaultIndent / 2;
    vertical-align: middle;
  }
  input[type=text],
  input[type=number],
  input[type=tel],
  input[type=email],
  input[type=password],
  select {
    appearance: none;
    display: inline-block;
    width: 100%;
    height: 30px;
    padding: 0 $contentDefaultIndent / 2;
    border: 1px solid $uiBorderColor;
    border-radius: 4px;
    background-color: $inputBackgroundColor;
    line-height: 30px;
    text-align: center;
    vertical-align: middle;
    &:not(:placeholder-shown),
    &:required {
      &:invalid {
        box-shadow: inset 0 0 7px #ff2500;
      }
    }
  }
  select {
    text-align-last: center;
  }
  span {
    vertical-align: middle;
    &[data-custominput] {
      display: inline-flex;
      justify-content: center;
      width: 100%;
      margin: 10px 0 15px 0;
      position: relative;
      & > label {
        position: absolute;
        bottom: 7px;
        left: 10px;
        margin: 0;
        transition: transform 0.2s;
        color: #757575;
        font-size: 15px;
      }
      & > input[type=text]:focus + label {
        transform: translateY(-25px);
        color: #37aebe;
      }
    }
  }
}

.nextPosition {
  font-weight: 700;
  color: #6bd849;
}

// Hide bet win bonus line
#bet-win-bonus {
  display: none;
  overflow: hidden;
  opacity: 0;
}
